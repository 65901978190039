export const LoginFormModel = {
  settings: {
    hideRequiredMark: true,
    layout: 'inline',
  },
  fields: [
    {
      tag: 'input',
      type: 'email',
      name: 'email',
      validateTrigger: 'onBlur',
      rules: [
        {
          type: 'email',
          message: 'forms.common.validation_messages.not_valid_email',
        },
        {
          required: true,
          message: 'forms.common.validation_messages.not_empty_email',
        },
      ],
      placeholder: 'forms.auth_form.placeholder.email',
    },
    {
      tag: 'input',
      type: 'password',
      name: 'password',
      autoComplete: 'on',
      validateTrigger: 'onBlur',
      rules: [
        {
          pattern: '^[a-zA-Z0-9!@#$%^&*)(+=._-]{6,32}$',
          message: 'forms.common.validation_messages.not_valid_password',
        },
        {
          required: true,
          message: 'forms.common.validation_messages.not_empty_password',
        },
      ],
      placeholder: 'forms.auth_form.placeholder.password',
    },
  ],
};
