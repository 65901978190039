// @flow
import React from 'react';
import type { Node } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { FormBuilder } from '@riseart/form';
import { Heading, Link } from '@riseart/common';
import { meta as META_ENUM } from 'Enum';
import { UrlAssembler } from 'shared_services/riseart/utils/UrlAssembler';
import { LoginFormModel } from 'shared_models/forms/Login';
import { TermsSection } from 'shared_components/forms/common/TermsSection';

import {
  loginCls,
  loginFormCls,
  loginFormTitleCls,
  loginFormFooterCls,
  loginFormFooterCenterCls,
} from 'shared_components/forms/login/Form.less';

const { ATTRIBUTE_CONTENT } = META_ENUM;

type Props = {
  submitListener: Function,
  registerPath: String,
  showLegalInfo: boolean,
  showSignup?: boolean,
  queryString: string,
};

/**
 * LoginForm
 *
 * @param {Props} props
 */
export const LoginForm = ({
  showSignup = true,
  registerPath,
  submitListener,
  queryString,
  showLegalInfo,
}: Props): Node => {
  const intl = useIntl();
  const loginButtonText = intl.formatMessage({ id: 'forms.auth_form.login_button_text' });
  const registerText =
    showSignup &&
    registerPath &&
    intl.formatMessage({ id: 'forms.auth_form.register_button_text' });
  const forgotPasswordText = intl.formatMessage({ id: 'forms.auth_form.forget_password_text' });

  return (
    <div className={loginCls}>
      <Heading tag="h3" level="5" align="center" className={loginFormTitleCls}>
        {intl.formatMessage({ id: 'forms.auth_form.sign_in_text' })}
      </Heading>
      <FormBuilder
        intl={intl}
        className={loginFormCls}
        settings={LoginFormModel.settings}
        fields={LoginFormModel.fields}
        submitActionLabel={loginButtonText}
        onSubmit={submitListener}
      />
      <div
        className={classNames(
          loginFormFooterCls,
          !showSignup || !registerPath ? loginFormFooterCenterCls : null,
        )}
      >
        {showSignup && registerPath ? (
          <Link
            to={registerPath}
            type="light"
            rel={ATTRIBUTE_CONTENT.NO_FOLLOW}
            title={registerText}
          >
            {registerText}
          </Link>
        ) : null}
        <Link
          type="light"
          to={UrlAssembler.byRouteKey('forgotPassword', { search: queryString })}
          rel={ATTRIBUTE_CONTENT.NO_FOLLOW}
          title={forgotPasswordText}
        >
          {forgotPasswordText}
        </Link>
      </div>
      {showLegalInfo && <TermsSection translationValues={{ actionLabel: loginButtonText }} />}
    </div>
  );
};
